import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

interface IWellProduction {
  bopd: number;
  bwpd: number;
  mscfd: number;
  pressureHead: number;
  temperatureHead: number;
  pressureBottom: number;
  temperatureBottom: number;
  pipeDiameter: number;
  pipeDepth: number;
}

const emptyWellProduction: IWellProduction = {
  bopd: 0,
  bwpd: 0,
  mscfd: 0,
  pressureHead: 0,
  temperatureHead: 0,
  pressureBottom: 0,
  temperatureBottom: 0,
  pipeDiameter: 0,
  pipeDepth: 0,
};

interface ILaboratoryAnalysis {
  chlorine: number;
  co2Fraction: number;
  alkalinity: number;
  sodium: number;
  potassium: number;
  magnesium: number;
  calcium: number;
  strontium: number;
  barium: number;
  sulfate: number;
  carboxylicAcid: number;
  iron:number;
}

const emptyLaboratotyAnalysis: ILaboratoryAnalysis = {
  chlorine: 0,
  co2Fraction: 0,
  alkalinity: 0,
  sodium: 0,
  potassium: 0,
  magnesium: 0,
  calcium: 0,
  strontium: 0,
  barium: 0,
  sulfate: 0,
  carboxylicAcid: 0,
  iron: 0,
};

interface IChemicalsInjection {
  anticorrosiveDose: number;
  antiscaleDose: number;
  anticorrosivePrice: number;
  antiscalePrice: number;
}

const emptyChemicalInyection: IChemicalsInjection = {
  anticorrosiveDose: 0,
  antiscaleDose: 0,
  anticorrosivePrice: 0,
  antiscalePrice: 0,
};

export interface IWellInputData {
  wellName: string;
  wellProduction: IWellProduction;
  laboratoryAnalysis: ILaboratoryAnalysis;
  chemicalsInjection: IChemicalsInjection;
}

export interface ICorrosionResults {
  corrosionHead: number;
  corrosionDepth: number;
  corrosionMax: number;
  corrosionRiskHead: string;
  corrosionRiskDepth: string;
  corrosionRiskMax: string;
  corrosion: number[];
  depth: number[];
  pressure: number[];
  temperature: number[];
  corrosionRisk: string[];
}

const emptyCorrosionResults: ICorrosionResults = {
  corrosionHead: 0,
  corrosionDepth: 0,
  corrosionMax: 0,
  corrosionRiskHead: "",
  corrosionRiskDepth: "",
  corrosionRiskMax: "",
  corrosion: [],
  depth: [],
  pressure: [],
  temperature: [],
  corrosionRisk: [],
};

interface ISaturationResults {
  saturationIndexHead: number;
  saturationIndexDepth: number;
  saturationIndexMax: number;
  encrustationRiskHead: string;
  encrustationRiskDepth: string;
  encrustationRiskMax: string;
  calciteSaturationIndex: number[];
  depth: number[];
  pressure: number[];
  temperature: number[];
  solids: number[];
  encrustationRisk: string[];
}

interface ICriticalityValues {
  total_criticality: number;
  production_criticality: number;
  corrosion_criticality: number;
  scale_criticality: number;
}

const emptyCriticalityValues = {
  total_criticality: 0,
  production_criticality: 0,
  corrosion_criticality: 0,
  scale_criticality: 0,
};

interface ICriticalityResults {
  bopd: number;
  totalCriticalityIndex: number;
  treatmentPriority: string;
  criticalityValues: ICriticalityValues;
}

const emptySaturationResults: ISaturationResults = {
  saturationIndexHead: 0,
  saturationIndexDepth: 0,
  saturationIndexMax: 0,
  encrustationRiskHead: "",
  encrustationRiskDepth: "",
  encrustationRiskMax: "",
  calciteSaturationIndex: [],
  depth: [],
  pressure: [],
  temperature: [],
  solids: [],
  encrustationRisk: [],
};

const emptyCriticalityResults: ICriticalityResults = {
  bopd: 0,
  totalCriticalityIndex: 0,
  treatmentPriority: "",
  criticalityValues: emptyCriticalityValues,
};

interface IChemicalResults {
  actualAnticorrosiveDose: number;
  recommendedAnticorrosiveDose: number;
  anticorrosiveInjectionStatus: string;
  potentialAnticorrosiveSavings: number;
  actualAntiscaleDose: number;
  recommendedAntiscaleDose: number;
  antiscaleInjectionStatus: string;
  potentialAntiscaleSavings: number;
}

const emptyChemicalResults: IChemicalResults = {
  actualAnticorrosiveDose: 0,
  recommendedAnticorrosiveDose: 0,
  anticorrosiveInjectionStatus: "",
  potentialAnticorrosiveSavings: 0,
  actualAntiscaleDose: 0,
  recommendedAntiscaleDose: 0,
  antiscaleInjectionStatus: "",
  potentialAntiscaleSavings: 0,
};

interface IWellResults {
  wellName: string;
  corrosionResult: ICorrosionResults;
  saturationIndexResult: ISaturationResults;
  wellCriticalityResultOutput: ICriticalityResults;
  chemicalOptimizationResult: IChemicalResults;
}

const emptyWellResults: IWellResults = {
  wellName: "",
  corrosionResult: emptyCorrosionResults,
  saturationIndexResult: emptySaturationResults,
  wellCriticalityResultOutput: emptyCriticalityResults,
  chemicalOptimizationResult: emptyChemicalResults,
};

export interface IWellProResults {
  date: string;
  wellName: string;
  corrosionResult: ICorrosionResults;
  saturationIndexResult: ISaturationResults;
  wellCriticalityResultOutput: ICriticalityResults;
  chemicalOptimizationResult: IChemicalResults;
  wellProduction: IWellProduction;
  laboratoryAnalysis: ILaboratoryAnalysis;
  chemicalsInjection: IChemicalsInjection;
}

const emptyWellProResults: IWellProResults = {
  date: "",
  wellName: "",
  corrosionResult: emptyCorrosionResults,
  saturationIndexResult: emptySaturationResults,
  wellCriticalityResultOutput: emptyCriticalityResults,
  chemicalOptimizationResult: emptyChemicalResults,
  wellProduction: emptyWellProduction,
  laboratoryAnalysis: emptyLaboratotyAnalysis,
  chemicalsInjection: emptyChemicalInyection,
};

interface State {
  wellResults: IWellResults;
  wellProResults: IWellProResults[];
  selectedPlan: "free" | "pro";
  areProResultsFetched: boolean;
  reportReference: number;
  proPlanErrors: string;
}

const initialState: State = {
  wellResults: emptyWellResults,
  wellProResults: [emptyWellProResults],
  selectedPlan: "free",
  areProResultsFetched: false,
  reportReference: -1,
  proPlanErrors: "",
};

function reduceDecimals(num: number, fractionDigits: number): number {
  return Number(num.toFixed(fractionDigits));
}

const planSlice = createSlice({
  name: "planData",
  initialState,
  reducers: {
    saveResults: (state, action: PayloadAction<IWellResults>) => {
      // use 2 decimals
      action.payload.corrosionResult.corrosionDepth = reduceDecimals(
        action.payload.corrosionResult.corrosionDepth,
        2
      );
      action.payload.corrosionResult.corrosionHead = reduceDecimals(
        action.payload.corrosionResult.corrosionHead,
        2
      );
      action.payload.corrosionResult.corrosionMax = reduceDecimals(
        action.payload.corrosionResult.corrosionMax,
        2
      );
      action.payload.corrosionResult.depth.forEach((element, index) => {
        action.payload.corrosionResult.depth[index] = reduceDecimals(
          element,
          2
        );
      });
      action.payload.corrosionResult.pressure.forEach((element, index) => {
        action.payload.corrosionResult.pressure[index] = reduceDecimals(
          element,
          2
        );
      });
      action.payload.corrosionResult.temperature.forEach((element, index) => {
        action.payload.corrosionResult.temperature[index] = reduceDecimals(
          element,
          2
        );
      });

      action.payload.saturationIndexResult.depth.forEach((element, index) => {
        action.payload.saturationIndexResult.depth[index] = reduceDecimals(
          element,
          2
        );
      });
      action.payload.saturationIndexResult.pressure.forEach(
        (element, index) => {
          action.payload.saturationIndexResult.pressure[index] = reduceDecimals(
            element,
            2
          );
        }
      );
      action.payload.saturationIndexResult.saturationIndexDepth =
        reduceDecimals(
          action.payload.saturationIndexResult.saturationIndexDepth,
          2
        );
      action.payload.saturationIndexResult.saturationIndexHead = reduceDecimals(
        action.payload.saturationIndexResult.saturationIndexHead,
        2
      );
      action.payload.saturationIndexResult.saturationIndexMax = reduceDecimals(
        action.payload.saturationIndexResult.saturationIndexMax,
        2
      );
      action.payload.saturationIndexResult.temperature.forEach(
        (element, index) => {
          action.payload.saturationIndexResult.temperature[index] =
            reduceDecimals(element, 2);
        }
      );

      state.wellResults = action.payload;
    },

    saveSelectedPlan: (state, action: PayloadAction<"free" | "pro">) => {
      state.selectedPlan = action.payload;
    },
    saveProPlanErrors: (state, action: PayloadAction<string>) => {
      state.proPlanErrors = action.payload;
    },
    saveProPlanResults: (state, action: PayloadAction<IWellProResults[]>) => {
      action.payload.forEach(
        ({ corrosionResult }, index) =>
          (corrosionResult.corrosionDepth = reduceDecimals(
            action.payload[index].corrosionResult.corrosionDepth,
            2
          ))
      );

      action.payload.forEach(
        ({ corrosionResult }, index) =>
          (corrosionResult.corrosionHead = reduceDecimals(
            action.payload[index].corrosionResult.corrosionHead,
            2
          ))
      );

      action.payload.forEach(
        ({ corrosionResult }, index) =>
          (corrosionResult.corrosionMax = reduceDecimals(
            action.payload[index].corrosionResult.corrosionMax,
            2
          ))
      );

      action.payload.forEach(
        ({ saturationIndexResult }, index) =>
          (saturationIndexResult.saturationIndexDepth = reduceDecimals(
            action.payload[index].saturationIndexResult.saturationIndexDepth,
            2
          ))
      );

      action.payload.forEach(
        ({ saturationIndexResult }, index) =>
          (saturationIndexResult.saturationIndexHead = reduceDecimals(
            action.payload[index].saturationIndexResult.saturationIndexHead,
            2
          ))
      );

      action.payload.forEach(
        ({ saturationIndexResult }, index) =>
          (saturationIndexResult.saturationIndexHead = reduceDecimals(
            action.payload[index].saturationIndexResult.saturationIndexHead,
            2
          ))
      );

      action.payload.forEach(
        ({ saturationIndexResult }, index) =>
          (saturationIndexResult.saturationIndexMax = reduceDecimals(
            action.payload[index].saturationIndexResult.saturationIndexMax,
            2
          ))
      );

      state.wellProResults = action.payload;
    },
    saveReportReference: (state, action: PayloadAction<number>) => {
      state.reportReference = action.payload;
    },
    clearResults: (state) => {
      state.wellResults = emptyWellResults;
      state.reportReference = -1;
      state.areProResultsFetched = false;
    },
    clearProResults: (state) => {
      state.wellProResults = [emptyWellProResults];
      state.reportReference = -1;
      state.proPlanErrors = "";
    },
    clearProPlanErrors: (state) => {
      state.proPlanErrors = "";
    },
    setFetchedMockedDataStatus: (state, action: PayloadAction<boolean>) => {
      state.areProResultsFetched = action.payload;
    },
  },
});
export const {
  saveResults,
  saveProPlanResults,
  saveReportReference,
  clearResults,
  clearProResults,
  saveSelectedPlan,
  setFetchedMockedDataStatus,
  saveProPlanErrors,
  clearProPlanErrors,
} = planSlice.actions;

export default planSlice.reducer;

export const selectResults = (state: RootState): IWellResults =>
  state.sourcesManagement.wellResults;
export const selectSelectedPlan = (state: RootState): "free" | "pro" =>
  state.sourcesManagement.selectedPlan;
export const selectProResults = (state: RootState): IWellProResults[] =>
  state.sourcesManagement.wellProResults;
export const selectFetchedProResultsStatus = (state: RootState): boolean =>
  state.sourcesManagement.areProResultsFetched;
export const selectReportReference = (state: RootState): number =>
  state.sourcesManagement.reportReference;
export const selectProPlanErrors = (state: RootState): string =>
  state.sourcesManagement.proPlanErrors;
