import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import PungoDemoAccordion from "../../../../pungo-ui/PungoDemoAccordion";
import PungoTable from "pungo-ui/PungoTable";
import { selectProResults } from "store/slices/plan";

import styles from "./index.module.scss";
import "../../../../styles/variables.scss";

const DataInputProSection: React.FC = () => {
  const { t } = useTranslation();

  const proPlanResults = useSelector(selectProResults);

  const labelsForProductionWellTable = [
    t("proPlan.tableLabels.wellProduction.well"),
    t("proPlan.tableLabels.wellProduction.date"),
    t("proPlan.tableLabels.wellProduction.bbpd"),
    t("proPlan.tableLabels.wellProduction.bwpd"),
    t("proPlan.tableLabels.wellProduction.mscfd"),
    t("proPlan.tableLabels.wellProduction.headPressure"),
    t("proPlan.tableLabels.wellProduction.headTemperature"),
    t("proPlan.tableLabels.wellProduction.bottomPressure"),
    t("proPlan.tableLabels.wellProduction.bottomTemperature"),
    t("proPlan.tableLabels.wellProduction.diameter"),
    t("proPlan.tableLabels.wellProduction.depth"),
  ];

  const labelsForLaboratoryAnalysisTable = [
    t("proPlan.tableLabels.laboratoryAnalysis.well"),
    t("proPlan.tableLabels.laboratoryAnalysis.date"),
    t("proPlan.tableLabels.laboratoryAnalysis.chlorides"),
    t("proPlan.tableLabels.laboratoryAnalysis.CO2"),
    t("proPlan.tableLabels.laboratoryAnalysis.alkalinity"),
    t("proPlan.tableLabels.laboratoryAnalysis.sodium"),
    t("proPlan.tableLabels.laboratoryAnalysis.potasium"),
    t("proPlan.tableLabels.laboratoryAnalysis.magnesium"),
    t("proPlan.tableLabels.laboratoryAnalysis.calcium"),
    t("proPlan.tableLabels.laboratoryAnalysis.strontium"),
    t("proPlan.tableLabels.laboratoryAnalysis.barium"),
    t("proPlan.tableLabels.laboratoryAnalysis.sulfates"),
    t("proPlan.tableLabels.laboratoryAnalysis.carboxylicAcids"),
    t("proPlan.tableLabels.laboratoryAnalysis.iron"),
  ];

  const labelsForChemicalInjectionTable = [
    t("proPlan.tableLabels.chemicalInyection.well"),
    t("proPlan.tableLabels.chemicalInyection.date"),
    t("proPlan.tableLabels.chemicalInyection.anticorrosiveDosage"),
    t("proPlan.tableLabels.chemicalInyection.antiscaleDosage"),
    t("proPlan.tableLabels.chemicalInyection.anticorrosivePrice"),
    t("proPlan.tableLabels.chemicalInyection.antiscalePrice"),
  ];

  const getWellProduction = () => (
    <PungoTable
      values={proPlanResults?.map(({ wellName, wellProduction, date }) => {
        return [
          wellName,
          date,
          wellProduction.bopd % 1 !== 0
          ? wellProduction.bopd.toFixed(2)
          : wellProduction.bopd,
        wellProduction.bwpd % 1 !== 0
          ? wellProduction.bwpd.toFixed(2)
          : wellProduction.bwpd,
        wellProduction.mscfd % 1 !== 0
          ? wellProduction.mscfd.toFixed(2)
          : wellProduction.mscfd,
        wellProduction.pressureHead % 1 !== 0
          ? wellProduction.pressureHead.toFixed(2)
          : wellProduction.pressureHead,
        wellProduction.temperatureHead % 1 !== 0
          ? wellProduction.temperatureHead.toFixed(2)
          : wellProduction.temperatureHead,
        wellProduction.pressureBottom % 1 !== 0
          ? wellProduction.pressureBottom.toFixed(2)
          : wellProduction.pressureBottom,
        wellProduction.temperatureBottom % 1 !== 0
          ? wellProduction.temperatureBottom.toFixed(2)
          : wellProduction.temperatureBottom,
        wellProduction.pipeDiameter % 1 !== 0
          ? wellProduction.pipeDiameter.toFixed(2)
          : wellProduction.pipeDiameter,
        wellProduction.pipeDepth % 1 !== 0
          ? wellProduction.pipeDepth.toFixed(2)
          : wellProduction.pipeDepth,
        ];
      })}
      labels={labelsForProductionWellTable}
      height={300}
    />
  );

  const getLaboratoryAnalysis = () => (
    <PungoTable
      values={proPlanResults?.map(({ date, wellName, laboratoryAnalysis }) => {
        return [
          wellName,
          date,
          laboratoryAnalysis.chlorine % 1 !== 0
          ? laboratoryAnalysis.chlorine.toFixed(2)
          : laboratoryAnalysis.chlorine,
          laboratoryAnalysis.co2Fraction % 1 !== 0
          ? laboratoryAnalysis.co2Fraction.toFixed(2)
          : laboratoryAnalysis.co2Fraction,
          laboratoryAnalysis.alkalinity % 1 !== 0
          ? laboratoryAnalysis.alkalinity.toFixed(2)
          : laboratoryAnalysis.alkalinity,
          laboratoryAnalysis.sodium % 1 !== 0
          ? laboratoryAnalysis.sodium.toFixed(2)
          : laboratoryAnalysis.sodium,
          laboratoryAnalysis.potassium % 1 !== 0
          ? laboratoryAnalysis.potassium.toFixed(2)
          : laboratoryAnalysis.potassium,
          laboratoryAnalysis.magnesium % 1 !== 0
          ? laboratoryAnalysis.magnesium.toFixed(2)
          : laboratoryAnalysis.magnesium,
          laboratoryAnalysis.calcium % 1 !== 0
          ? laboratoryAnalysis.calcium.toFixed(2)
          : laboratoryAnalysis.calcium,
          laboratoryAnalysis.strontium % 1 !== 0
          ? laboratoryAnalysis.strontium.toFixed(2)
          : laboratoryAnalysis.strontium,
          laboratoryAnalysis.barium % 1 !== 0
          ? laboratoryAnalysis.barium.toFixed(2)
          : laboratoryAnalysis.barium,
          laboratoryAnalysis.sulfate % 1 !== 0
          ? laboratoryAnalysis.sulfate.toFixed(2)
          : laboratoryAnalysis.sulfate,
          laboratoryAnalysis.carboxylicAcid % 1 !== 0
          ? laboratoryAnalysis.carboxylicAcid.toFixed(2)
          : laboratoryAnalysis.carboxylicAcid,
          laboratoryAnalysis.iron % 1 !== 0
          ? laboratoryAnalysis.iron.toFixed(2)
          : laboratoryAnalysis.iron,
        ];
      })}
      labels={labelsForLaboratoryAnalysisTable}
      height={300}
    />
  );

  const getChemicalInjection = () => (
    <div className={styles.chemicalInyectionContainer}>
      <div className={styles.note}>
        <Trans>{t("proPlan.labels.chemicalNote")}</Trans>
      </div>
      <PungoTable
        values={proPlanResults?.map(
          ({ date, wellName, chemicalsInjection }) => {
            return [
              wellName,
              date,
              chemicalsInjection.anticorrosiveDose % 1 !== 0
              ? chemicalsInjection.anticorrosiveDose.toFixed(2)
                : chemicalsInjection.anticorrosiveDose,
              chemicalsInjection.antiscaleDose % 1 !== 0
              ? chemicalsInjection.antiscaleDose.toFixed(2)
                : chemicalsInjection.antiscaleDose,
              chemicalsInjection.anticorrosivePrice % 1 !== 0
              ? chemicalsInjection.anticorrosivePrice.toFixed(2)
                : chemicalsInjection.anticorrosivePrice,
              chemicalsInjection.antiscalePrice % 1 !== 0
              ? chemicalsInjection.antiscalePrice.toFixed(2)
                : chemicalsInjection.antiscalePrice,
            ];
          }
        )}
        labels={labelsForChemicalInjectionTable}
        height={300}
      />
    </div>
  );

  return (
    <PungoSectionCard title={t("proPlan.section.dataInput")}>
      <div className={styles.container}>
        <div className={styles.accordionContainer}>
          <PungoDemoAccordion
            title={t("proPlan.labels.wellProduction")}
            content={getWellProduction()}
          />
        </div>
        <div className={styles.accordionContainer}>
          <PungoDemoAccordion
            title={t("proPlan.labels.laboratoryAnalysis")}
            content={getLaboratoryAnalysis()}
          />
        </div>
        <div className={styles.accordionContainer}>
          <PungoDemoAccordion
            title={t("proPlan.labels.chemicalsInjection")}
            content={getChemicalInjection()}
          />
        </div>
      </div>
    </PungoSectionCard>
  );
};

export default DataInputProSection;
